/* eslint-disable @typescript-eslint/naming-convention */
export class WorkTime {
  public Id: string;
  public ClockIn: Date;
  public ClockOut: Date;
  public UserId: number;
  public CardId: string;
  public Comment: string;
  public CheckinComment: string;
  public CheckoutComment: string;
  public startTime?: number;
  public endTime?: number;
  public SubsTime?: number;
}

export class MassWorkTime {
    public Users: number [];
    public Comment: string;
}
