import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppUrls } from 'src/app/helpers/app-urls';

@Injectable({
  providedIn: 'root'
})
export class TagService {

apiUrl: string;

  constructor(private httpclient: HttpClient) {
    const urls = new AppUrls();
    this.apiUrl = urls.apiUrl;
  }

  getTags(page: number, size: number, orgid: number) {
    const urls = new AppUrls();
    this.apiUrl = urls.apiUrl;
    const url = this.apiUrl + '/api/tag/' + page + '/' + size + '/' + orgid + '/';
    return this.httpclient.get<any>(url);
  }

}
