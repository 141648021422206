export class Roles {
  // user profile
  firstName: string;
  lastName: string;
  // time tracking roles

  timeTrackingSection: boolean;
  timeTrackingExpert: boolean;

  // ime loging roles

  timeLoggingSection: boolean;
  timeLoggingExpert: boolean;

  // attendances roles
  sessionSection: boolean;
  sessionExpert: boolean;

  // checkpoints roles
  checkPointSection: boolean;

  // terminal roles
  terminalSection: boolean;

  // account roles
  accountSection: boolean;
  accountExpert: boolean;

  // statistics roles
  statisticsSection: boolean;

  // card roles
  cardSection: boolean;
  cardExpert: boolean;

  // person roles
  personSection: boolean;
  personExpert: boolean;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  ManageSessions: boolean;

  url: boolean;

  absenceExpert: boolean;

  // calendar roles
  calendarSection: boolean;
  calendarExpert: boolean;

  // session type roles
  sessionTypeSection: boolean;
  sessionTypeExpert: boolean;

  // config roles
  configSection: boolean;
  configExpert: boolean;

  // profile roles
  profileSection: boolean;
  profileExpert: boolean;

  // compliance roles
  complianceSection: boolean;
  complianceExpert: boolean;
}
