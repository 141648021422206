import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CheckUploadService {

  sessionsUploaded$ = new BehaviorSubject<string>('N');
  isLogin$ = new BehaviorSubject<boolean>(false);

  constructor() {
    // constructor
   }

   setUploaded(uploaded: string): void {
    this.sessionsUploaded$.next(uploaded);
  }

  setLogin(value: boolean): void {
    this.isLogin$.next(value);
  }

}
