/* eslint-disable @typescript-eslint/naming-convention */
export class KeyValue {
  public Key: string;
  public Value: string;
}

export class StringUpdater {
  public Id: string;
  public Values: KeyValue[];
  public AdditionalValue: string;
}

export class Int64Updater {
  public Id: number;
  public Values: KeyValue[];
  public Value: string;
  public AdditionalValue: string;
}


