import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap, catchError } from 'rxjs/operators';
import { AppUrls } from 'src/app/helpers/app-urls';
import { Int64Updater, StringUpdater } from 'src/app/models/key-value';
import { MassWorkTime, WorkTime } from 'src/app/models/work-time';

@Injectable({
  providedIn: 'root'
})
export class TimeTrackingService {

  apiUrl: string;

  constructor(private httpclient: HttpClient) {
    const urls = new AppUrls();
    this.apiUrl = urls.apiUrl;
  }

  getTimeTrackings(page, size, id, uid) {
    const urls = new AppUrls();
    this.apiUrl = urls.apiUrl;

    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/TimeTracking/' + page + '/' + size + '/' + id + '/' + uid + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  postTimeTracking(wtime: WorkTime) {
    const headers = new HttpHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': 'application/json'
    });
    return this.httpclient.post(this.apiUrl + '/api/TimeTracking', wtime, {headers, responseType: 'text'})
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  putTimeTracking(val: Int64Updater) {
    return this.httpclient.put(this.apiUrl + '/api/TimeTracking/' + val.Id, val)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  clockin(userid: number, val: WorkTime[]) {
    return this.httpclient.put(this.apiUrl + '/api/TimeTracking/clockin/' + userid, val)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  clockout(userid: number, val: MassWorkTime) {
    return this.httpclient.put(this.apiUrl + '/api/TimeTracking/clockout/' + userid, val)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  deleteTimeTracking(id: number) {
    return this.httpclient.delete(this.apiUrl + '/api/TimeTracking/' + id)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  private handleError(error: any) {
    throw error;
    return error;
  }

}
