import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { AppUrls } from 'src/app/helpers/app-urls';
import { Int64Updater } from 'src/app/models/key-value';
import { WorkTime } from 'src/app/models/work-time';

@Injectable({
  providedIn: 'root'
})
export class TerminalServe {

  apiUrl: string;

  constructor(private httpclient: HttpClient) {
    const urls = new AppUrls();
    this.apiUrl = urls.apiUrl;
  }

  getTerminalInfos(page, size, id, cardid,start,end) {
    // tslint:disable-next-line: max-line-length

    console.log(page,size,id,cardid,start,end);
    const url = this.apiUrl + '/api/Terminal/' + page + '/' + size + '/' + id + '/' + cardid + '/'+ start + '/'+ end + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }


  postTerminalClockIn(wtime: WorkTime): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.httpclient.post(this.apiUrl + '/api/terminal', wtime, {headers: headers, responseType: 'text'})
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  putTimeTracking(cardid: string, val: Int64Updater) {
    return this.httpclient.put<any>(this.apiUrl + '/api/terminal/' + cardid, val)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  deleteTimeTracking(id: number) {
    return this.httpclient.delete(this.apiUrl + '/api/TimeTracking/' + id)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  private handleError(error: any) {
    throw error;
    return error;
  }

}
