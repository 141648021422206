import { Constants } from './../../helpers/constants';
import { Cookies } from './../../helpers/cookies';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { AppUrls } from 'src/app/helpers/app-urls';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject, firstValueFrom } from 'rxjs';
import { Profile } from 'src/app/models/profile';
import { Roles } from 'src/app/models/Roles';
import { EncryptDecrypt } from 'src/app/helpers/encrypt-decrypt';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  apiUrl: string;
  email: string;
  phone: string;
  pass: string;


  // eslint-disable-next-line @typescript-eslint/member-ordering
  userroles$ = new BehaviorSubject<Roles | null>(null);

  constructor(
    private httpclient: HttpClient,
    private cookies: Cookies,
    private cookieService: CookieService
  ) {
  }

  authenticate(email, phone, pass) {
    const urls = new AppUrls();
    this.apiUrl = urls.apiUrl;
    // tslint:disable-next-line: max-line-length
    this.phone = phone;
    this.email = email;
    this.pass = pass;
    const url =
      this.apiUrl +
      '/api/user/auth/' +
      this.email +
      '/' +
      this.phone +
      '/' +
      this.pass +
      '/';
    return this.httpclient.get<any>(url).pipe(
      tap(
        // Log the result or error
        (error) => this.handleError
      )
    );
  }

  getRoles(uid: number) {
    const urls = new AppUrls();
    this.apiUrl = urls.apiUrl;
    const url =
      this.apiUrl +
      '/api/user/auth/v2/' +
      uid;
    return this.httpclient.get<any>(url).pipe(
      tap(
        // Log the result or error
        (error) => this.handleError
      )
    );
  }

  async getUser(userName: string, passWord: string) {
    try {
      const data = await firstValueFrom(this.login(userName, passWord));

      this.createCookies(data.expires_in, data.id_token);

      return data;
    } catch (error: any) {
      if (error.toString().includes('user has no role')) {
        throw new Error('user has no role');
      } else {
        throw error;
      }
    }
  }

  login(email: string, passWord: string) {
    const urls = new AppUrls();
    this.apiUrl = urls.apiUrl;
    return this.httpclient.get<any>(
      this.apiUrl.replace('api.wtm', 'api.usm') +
        '/api/validateuser/0/0/' +
        email +
        '/ /' +
        passWord +
        '/ /'
    );
  }

  createCookies(timeOutSeconds: number, token: string) {
    const timeOut = new Date();
    timeOut.setSeconds(new Date().getSeconds() + timeOutSeconds);

    const ed = new EncryptDecrypt();

    this.cookieService.set(
      'htrgjktzhrt',
      ed.encrypt(token),
      { expires: timeOut }
    );
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  profile$ = new BehaviorSubject<Profile | null>(null);
  watchProfile(): Observable<Profile | null> {
    return this.profile$;
  }
  peekProfile(): Profile | null {
    return this.profile$.value;
  }
  pokeProfile(profile: Profile): void {
    this.profile$.next(profile);
  }


  watchUserRoles(): Observable<Roles | null> {
    return this.userroles$;
  }
  peekRoles(): Roles | null {
    return this.userroles$.value;
  }

  pokeRoles(role: Roles): void {
    this.userroles$.next(role);
  }

  logout() {
    const constants = new Constants();
    this.cookies.deleteCookie(constants.cookieDataName);
    this.cookies.deleteCookie(constants.userDataName);
    // this.router.navigateByUrl('/auth')
  }

  private handleError(error: any) {
    throw error;
    return error;
  }
}

