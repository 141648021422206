import { SingleCard } from './../../models/single-cards';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap, catchError } from 'rxjs/operators';
import { AppUrls } from 'src/app/helpers/app-urls';
import { Int64Updater, StringUpdater } from 'src/app/models/key-value';
import { SinglePerson } from 'src/app/models/single-person';

@Injectable({
  providedIn: 'root',
})
export class PersonService {
  apiUrl: string;

  constructor(private httpclient: HttpClient) {
    const urls = new AppUrls();
    this.apiUrl = urls.apiUrl;
  }

  getPersons(page: number, size: number, orgId: number, personId: number, idcard: string, personname: string) {
    // tslint:disable-next-line: max-line-length
    const url =
      this.apiUrl + '/api/Person/' + page + '/' + size + '/' + orgId + '/' + personId + '/' + idcard + '/' + personname + '/';

      // console.log(url);
    return this.httpclient.get<any>(url).pipe(
      tap(
        // Log the result or error
        (error) => this.handleError
      )
    );
  }

  createPerson(person: SinglePerson) {
    // console.log('this is the request: '+this.apiUrl + '/api/Person', person);
    return this.httpclient
      .post(this.apiUrl + '/api/Person/', person)
      .pipe(catchError(this.handleError));
  }

  updatePerson(val: StringUpdater) {

    // console.log('Valsrequest', val);
    return this.httpclient
      .put(this.apiUrl + '/api/Person/' + val.Id, val)
      .pipe(catchError(this.handleError));
  }

  deletePerson(id: number) {
    return this.httpclient
      .delete(this.apiUrl + '/api/Person/' + id)
      .pipe(catchError(this.handleError));
  }


  private handleError(error: any) {
    throw error;
    return error;
  }
}
