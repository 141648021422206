import { CookieService } from 'ngx-cookie-service';
import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UrlService } from '@wtm/wtmlibrary';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  urlService: UrlService;

  constructor(
    private router: Router,
    private platform: Platform,
    private cookieService: CookieService
  ) { }

  async canActivate() {
    if (this.platform.is('mobile') && !this.platform.is('mobileweb')) {
      const res = this.cookieService.get('url');

      if(res === undefined || res === null || res === '') {
        this.router.navigate(['/wtm/url']);
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

}
