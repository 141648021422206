import { CommentCategoryService } from './../services/comment-category/comment-category.service';
import { HttpClient } from '@angular/common/http';
import { Observable, tap, shareReplay } from 'rxjs';
import { AppUrls } from './app-urls';
import { AbsenceTypeService } from '../services/absence-type/absence-type.service';
import { CalendarTypeService } from '../services/calendar-type/calendar-type.service';
import { AccountService } from '../services/account/account.service';
import { Injectable, Injector } from '@angular/core';
import { PersonService } from '../services/person/person-service';
import { TagService } from '../services/tag/tag.service';

@Injectable({
  providedIn: 'root'
})
export class DataCache {
    static commentcategorydata$: Observable<any>;
    static absencetypedata$: Observable<any>;
    static calendartypedata$: Observable<any>;
    static accountdata$: Observable<any>;
    static persondata$: Observable<any>;
    static compliancetagdata$: Observable<any>;

    static commentCategoryData: any;
    static absenceTypeData: any;
    static calendarTypeData: any;
    static accountData: any;
    static personData: any;
    static complianceTagData: any;

    static http: any;
    static ccService: CommentCategoryService;
    static absTypeService: AbsenceTypeService;
    static calTypeService: CalendarTypeService;
    static tagService: TagService;

    static accountService: AccountService;
    static personService: PersonService;

    constructor(
      private injector: Injector
      ) {
        const orgid = +localStorage.getItem('organisationId');
        if (orgid === undefined || orgid === null || orgid === 0) {
          return;
        }

        DataCache.http = this.injector.get(HttpClient);

        DataCache.ccService = this.injector.get(CommentCategoryService);
        DataCache.absTypeService = this.injector.get(AbsenceTypeService);
        DataCache.calTypeService = this.injector.get(CalendarTypeService);

        DataCache.accountService = this.injector.get(AccountService);
        DataCache.personService = this.injector.get(PersonService);
        DataCache.tagService = this.injector.get(TagService);
}

    static loadData() {
        const url = new AppUrls();
        const apiUrl = url.apiUrl;

        const page = 0;
        const size = 0;
        const orgid = +localStorage.getItem('organisationId');
        if (orgid === undefined || orgid === null || orgid === 0) {
          return;
        }

        // get comment categories
        this.commentcategorydata$ = this.ccService.getCommentCategories(page, size, 0, orgid).pipe(
          tap(),
          shareReplay(1),
          tap()
        );

        // get absence types
        this.absencetypedata$ = this.absTypeService.getAbsenceTypes(page, size, 0, orgid).pipe(
          tap(),
          shareReplay(1),
          tap()
        );

        // get calendar types
        this.calendartypedata$ = this.calTypeService.getCalendarTypes(page, size, orgid, 0, 'null').pipe(
          tap(),
          shareReplay(1),
          tap()
        );

        // get accounts
        this.accountdata$ = this.accountService.getAllAccounts(1, 100, orgid, 0, 'null', 0).pipe(
          tap(),
          shareReplay(1),
          tap()
        );

        // get persons
        this.persondata$ = this.personService.getPersons(1, 100, orgid, 0, 'null', 'null').pipe(
          tap(),
          shareReplay(1),
          tap()
        );

        // get compliance tags
        this.compliancetagdata$ = this.tagService.getTags(1, 10000, orgid).pipe(
          tap(),
          shareReplay(1),
          tap()
        );
    }

    getCommentCategoryData() {
      DataCache.commentcategorydata$.subscribe(data => DataCache.commentCategoryData = data);
    }

    getAbsenceTypeData() {
      DataCache.absencetypedata$.subscribe(data => DataCache.absenceTypeData = data);
    }

    getCalendarTypeData() {
      DataCache.calendartypedata$.subscribe(data => DataCache.calendarTypeData = data);
    }

    getComplianceTagData() {
      DataCache.compliancetagdata$.subscribe(data => DataCache.complianceTagData = data);
    }

    clearData() {
        DataCache.commentCategoryData = null;
        DataCache.absenceTypeData = null;
        DataCache.calendarTypeData = null;
        DataCache.accountData = null;
        DataCache.personData = null;
        DataCache.complianceTagData = null;
    }
}
