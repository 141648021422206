import { Account, AccountStatus } from './../../models/account';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppUrls } from 'src/app/helpers/app-urls';
import { Int64Updater } from 'src/app/models/key-value';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  apiUrl: string;

  constructor(private httpclient: HttpClient) {
    const urls = new AppUrls();
    this.apiUrl = urls.apiUrl;
  }

  getAccounts(page: number, size: number, orgid: number, groupid: number, accid: number, accname: string) {
    const url = this.apiUrl + '/api/account/' + page + '/' + size + '/' + orgid + '/'
    + groupid + '/' + accid + '/' + accname + '/';
    return this.httpclient.get<any>(url);
  }

  getAllAccounts(page: number, size: number, orgid: number, accid: number, accname: string, userid: number) {
    const url = this.apiUrl + '/api/account/accounts/' + page + '/' + size + '/' + orgid + '/'
    + accid + '/' + accname + '/' + userid + '/';
    return this.httpclient.get<any>(url);
  }

  createAccount(acc: Account) {
    return this.httpclient
      .post(this.apiUrl + '/api/account/', acc);
  }

  updateAccount(val: Int64Updater) {
    return this.httpclient
      .put(this.apiUrl + '/api/account/' + val.Id, val);
  }

  updateAccountStatus(val: AccountStatus) {
    return this.httpclient
      .put<any>(this.apiUrl + '/api/account/status/' + val.Account.AccountId, val);
  }

}
