import { EncryptDecrypt } from './encrypt-decrypt';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})

export class Cookies {
  constructor(private edService: EncryptDecrypt,
    private cookieService: CookieService) {
    //
  }

  // set cookie.
  setCookie(name, value){
    // encrypt user data
     const udata = this.edService.encrypt(value);
    // const udata = JSON.stringify(value);
    this.cookieService.set(name, udata);
  }

  // get cookie
  getCookie(name){
    const cookie = this.cookieService.get(name);
    if (cookie !== undefined && cookie !== null && cookie !== '') {
      // return JSON.parse(cookie);
      return this.edService.decrypt(cookie);
    } else {
      return null;
    }
  }

  // delete cookie
  deleteCookie(name){
    this.cookieService.delete(name);
  }
}
