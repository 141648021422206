export class LocalData {
    public static clearData() {
        localStorage.setItem('active_timetracking', '');
        localStorage.setItem('active_session', '');
        localStorage.setItem('active_terminal', '');
        localStorage.setItem('active_checkpoint', '');
        localStorage.setItem('active_timelogging', '');
        localStorage.setItem('active_setting', '');
        localStorage.setItem('active_account', '');
        localStorage.setItem('active_overview', '');
        localStorage.setItem('active_administration', '');
        localStorage.setItem('active_person', '');
        localStorage.setItem('active_overview', '');
        localStorage.setItem('active_calendar', '');
        localStorage.setItem('active_sessiontype', '');
        localStorage.setItem('active_config', '');
        localStorage.setItem('active_profile', '');
        localStorage.setItem('active_compliance', '');
    }
}
