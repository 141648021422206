import { Passwords } from './passwords';
import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})

export class EncryptDecrypt {
  constructor() {
    //
  }

  //The set method is use for encrypt the value.
  encrypt(value){
    const pass = new Passwords();
    const key = pass.password;
    return CryptoJS.AES.encrypt(JSON.stringify(value), key).toString();
  }

  //The get method is use for decrypt the value.
  decrypt(value){
    const pass = new Passwords();
    const key = pass.password;
    const decrypt = CryptoJS.AES.decrypt(value, key).toString(CryptoJS.enc.Utf8);
    return JSON.parse(decrypt);
  }
}
