/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/quotes */
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // production: false,
  // apiUrl: 'http://144.91.108.116:3016',
  // apiUrl: 'https://localhost:7260',

  production: true,
  apiUrl: window["env"]["APIURL"] || "default",
  appUrl: window["env"]["APPURL"] || "default",
  usmApiUrl: window["env"]["USMAPIURL"] || "default",
  flmApiUrl: window["env"]["FLMAPIURL"] || "default",
  keycloakUrl: window["env"]["KEYCLOAKURL"] || "default",
  realm: window["env"]["REALM"] || "default",
  clientid: window["env"]["CLIENTID"] || "default",
  portalApiUrl: window["env"]["PORTALAPIURL"] || "default"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error'  // Included with Angular CLI.
