import { Injectable } from '@angular/core';
import { GroupService, SessionService, UserService } from '@wtm/wtmlibrary';
import { NavController, Platform } from '@ionic/angular';
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { Device } from '@capacitor/device';
import { Constants } from './constants';
import { Cookies } from './cookies';
import { LocalData } from './local-data';
import { FirebaseCrashlytics } from '@capacitor-firebase/crashlytics';

@Injectable({
  providedIn: 'root'
})

export class Generic {
  constructor(
    private userService: UserService,
    private groupService: GroupService,
    private sessionService: SessionService,
    private platform: Platform,
    private nav: NavController,
    private cookies: Cookies
  ) {
    //
  }

  downloadData() {
    try {
      if (this.platform.is('mobile') && !this.platform.is('mobileweb')) {
        this.logEvent('downloadUserCards', { startTime: new Date().toString() });
        const orgid = +localStorage.getItem('organisationId');
        if (orgid === undefined || orgid === null || orgid === 0) {
          return;
        }

        const size = 30;

        console.log('GETTING SESSION TYPES...', new Date());
        this.sessionService.saveSessionTypeData(orgid, 0, true, size)
        .catch(error => console.log('GET SESSION TYPES ERROR', error));
        console.log('SESSION TYPES FINISHED');

        console.log('GETTING USERS AND GROUPS WITHOUT ASSIGNMENT...', new Date());
        this.groupService.saveGroupAndGroupUsers(orgid, size)
        .catch(error => console.log('GET USERS AND GROUPS WITHOUT ASSIGNMENT ERROR', error));
        console.log('GET USERS AND GROUPS WITHOUT ASSIGNMENT FINISHED');

        console.log('GETTING USER CARDS...', new Date());
        this.userService.getUserCards(orgid, size)
        .catch(error => console.log('GET USER CARDS ERROR', error));
        console.log('USER CARDS FINISHED');
      }
    } catch (error) {
      console.log('CACHE DATA ERROR', error);
      this.logEvent('downloadDataError', { error: JSON.stringify(error) });
    }
  }

  async setUser() {
    if (this.platform.is('mobile') && !this.platform.is('mobileweb')) {
      try {
        const userId = localStorage.getItem('userId');
        const name = localStorage.getItem('usersessionname');
        const devid = (await Device.getId()).identifier;
        const id = name.split(' ').join('_') + '_' + userId + '_' + devid;
        console.log('SET USER ID', id);
        this.logEvent('setLogUserId', { logUserId: id });

        // Use Firebase Auth uid
        FirebaseAnalytics.setUserId({
          userId: id
        }).catch(err => console.log('FIREBASE ANALYTICS LOG ERROR', err));

        FirebaseCrashlytics.setUserId({
          userId: id,
        }).catch(err => console.log('FIREBASE CRASHLYTICS LOG ERROR', err));
      } catch (error) {
        this.logEvent('setLogUserIdError', { error: JSON.stringify(error) });
      }
    }
  }

  logEvent(event: string, data: any) {
    try {
      console.log('EVENT', event, data);
      if (this.platform.is('mobile') && !this.platform.is('mobileweb')) {
        FirebaseAnalytics.logEvent({
          name: event,
          params: data
        }).catch(err => console.log('FIREBASE LOG ERROR', err));
      }
    } catch (error) {
      console.log('LOG ERROR', error);
    }
  }

  clearAndNavigateLoginRoot() {
    const constants = new Constants();
    this.cookies.deleteCookie(constants.cookieDataName);
    this.cookies.deleteCookie(constants.cookieRolesName);
    localStorage.setItem('token', '');
    localStorage.removeItem('organisationId');

    LocalData.clearData();
    this.nav.navigateRoot('wtm/login');
  }

}
