import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { CanActivate, Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class UrlGuardService implements CanActivate {

    constructor(
      private router: Router,
      private platform: Platform,
    ) { }

    canActivate() {
      if (this.platform.is('mobile') && !this.platform.is('mobileweb')) {
        return true;
      } else {
        this.router.navigateByUrl('/wtm');
        return false;
      }
    }

}
