import { SuccesTerminalModalPage } from './../succes-terminal-modal/succes-terminal-modal.page';
import { Component, OnInit } from '@angular/core';
import { LoadingController, ModalController, NavParams, ToastController } from '@ionic/angular';
import { Constants } from '../helpers/constants';
import { Cookies } from '../helpers/cookies';
import { Int64Updater, KeyValue } from '../models/key-value';
import { WorkTime } from '../models/work-time';
import { TimeTrackingService } from '../services/work-time/time-tracking/time-tracking.service';
import { TerminalClockModalPage } from '../terminal-clock-modal/terminal-clock-modal.page';
import { firstValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-terminal-modal',
  templateUrl: './terminal-modal.page.html',
  styleUrls: ['./terminal-modal.page.scss'],
})
export class TerminalModalPage implements OnInit {


  modalTitle: string;
  modelId: number;
  initialDate = new Date();

  loading = false;

  page = 1;
  size = 100;
  id = 0;
  uid = 0;

  worktimes = [];

  isCreateWorkTimeDisabled = true;
  dataReturned: any;

  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    private wtimeService: TimeTrackingService,
    private loadingCtrl: LoadingController,
    private toastController: ToastController,
    private cookies: Cookies,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    console.table(this.navParams);
    this.modelId = this.navParams.data.paramID;
    this.modalTitle = this.navParams.data.paramTitle;
    this.getWorkTimes();
  }

  async closeModal() {
    const onClosedData = 'Wrapped Up!';
    await this.modalController.dismiss(onClosedData);
  }

  async openTerminalClockInModal() {
    const msg = await firstValueFrom(this.translateService.get('TERMINALCLOCKEDINMODAL'));
    const modal = await this.modalController.create({
      component: TerminalClockModalPage,
      componentProps: {
        paramID: 124,
        paramTitle: msg
      }
    });

    modal.onDidDismiss().then((dataReturned) => {
      if (dataReturned !== null) {
        this.dataReturned = dataReturned.data;
        //alert('Modal Sent Data :'+ dataReturned);
      }
    });

    this.closeModal();

    return await modal.present();
}



  async getWorkTimes() {
    this.isCreateWorkTimeDisabled = false;
    this.loading = true;
    const load = await firstValueFrom(this.translateService.get('GENERAL.Processing'));
    this.loadingCtrl.create({keyboardClose: true, message: load + '...'})
    .then(async (loadingEl) => {
      loadingEl.present();
      const wtimes = await this.wtimeService.getTimeTrackings(this.page, this.size, this.id, this.uid).toPromise()
      .catch(err => this.handleError(err));

      console.log(wtimes);
      const workts = [];
      if (wtimes !== null && wtimes.length > 0) {
        for (const wtime of wtimes) {
          // check if an item already exists for the current day and disable the create button
          const dt = new Date(wtime.clockIn);
          const now = new Date();
          if (dt.getFullYear() === now.getFullYear() && dt.getMonth() === now.getMonth() && dt.getDate() === now.getDate()) {
            this.isCreateWorkTimeDisabled = true;
          }

          if (new Date(wtime.clockIn).getFullYear() === 1) {
            wtime.isClockedIn = false;
          } else {
            wtime.isClockedIn = true;
            wtime.cin = new Date(wtime.clockIn.toString().replace('T', ' ') + ' UTC');
          }
          if (new Date(wtime.clockOut).getFullYear() === 1) {
            wtime.isClockedOut = false;
          } else {
            wtime.isClockedOut = true;
            wtime.cout = new Date(wtime.clockOut.toString().replace('T', ' ') + ' UTC');
          }
          if (this.page === 1) {
            workts.push(wtime);
          } else {
            this.worktimes.push(wtime);
          }
        }
      }

      if (this.page === 1) {
        this.worktimes = workts;
      }
      loadingEl.dismiss();
      this.loading = false;
    });
  }

  async addWorkTime() {
    const constants = new Constants();
    const user = this.cookies.getCookie(constants.userDataName);

    const wtime = new WorkTime();
    wtime.UserId = user.userId;

    this.loading = true;
    const load = await firstValueFrom(this.translateService.get('GENERAL.Processing'));
    this.loadingCtrl.create({keyboardClose: true, message: load + '...'})
    .then(async (loadingEl) => {
      loadingEl.present();
      const res = await this.wtimeService.postTimeTracking(wtime).toPromise()
      .catch(err => this.handleError(err));
      if (res !== null && res > 0) {
        // success
          const msg = await firstValueFrom(this.translateService.get('CLOCKEDIN'));
        await this.presentToast(msg);
      }
      loadingEl.dismiss();
      this.loading = false;

      this.getWorkTimes();
    });
  }

  async clockIn(item) {
    const val = new Int64Updater();
    val.Id = item.id;
    val.Value = item.userId.toString();
    val.Values = [];

    const cin = new KeyValue();
    cin.Key = 'clock_in';
    cin.Value = new Date().toString();
    val.Values.push(cin);

    this.loading = true;
    const load = await firstValueFrom(this.translateService.get('GENERAL.Processing'));
    this.loadingCtrl.create({keyboardClose: true, message: load + '...'})
    .then(async (loadingEl) => {
      loadingEl.present();
      const res = await this.wtimeService.putTimeTracking(val).toPromise()
      .catch(err => this.handleError(err));
      if (res !== null && res === true) {
        // success
        const msg = await firstValueFrom(this.translateService.get('CLOCKEDIN'));
        await this.presentToast(msg);
      }
      loadingEl.dismiss();
      this.loading = false;

      this.getWorkTimes();
    });
  }

  async clockOut(item) {
    const val = new Int64Updater();
    val.Id = item.id;
    val.Value = item.userId.toString();
    val.Values = [];

    const cout = new KeyValue();
    cout.Key = 'clock_out';
    cout.Value = new Date().toString();
    val.Values.push(cout);

    this.loading = true;
    const load = await firstValueFrom(this.translateService.get('GENERAL.Processing'));
    this.loadingCtrl.create({keyboardClose: true, message: load + '...'})
    .then(async (loadingEl) => {
      loadingEl.present();
      const res = await this.wtimeService.putTimeTracking(val).toPromise()
      .catch(err => this.handleError(err));
      if (res !== null && res === true) {
        // success
          const msg = await firstValueFrom(this.translateService.get('CLOCKEDOUT'));
        await this.presentToast(msg);
      }
      loadingEl.dismiss();
      this.loading = false;

      this.getWorkTimes();
    });
  }

  async presentToast(msg: string) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      color: 'success',
      position: 'top',
      cssClass: 'ion-text-center'
    });
    toast.present();
  }

  handleError(err) {
    console.log(err);
    this.loadingCtrl?.dismiss();
    this.loading = false;
  }

}
