import { ClockResult } from './../models/clock_service';
import { TerminalServe } from './../services/terminal-service';
import { Router } from '@angular/router';
import { SuccesTerminalModalPage } from './../succes-terminal-modal/succes-terminal-modal.page';
import {
  Component,
  Directive,
  ElementRef,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import {
  LoadingController,
  ModalController,
  NavController,
  NavParams,
  ToastController,
} from '@ionic/angular';
import { Constants } from '../helpers/constants';
import { Cookies } from '../helpers/cookies';
import { Int64Updater, KeyValue } from '../models/key-value';
import { WorkTime } from '../models/work-time';
import { TimeTrackingService } from '../services/work-time/time-tracking/time-tracking.service';
import { TranslateConfigService } from '../services/translate-config.service';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-terminal-clock-modal',
  templateUrl: './terminal-clock-modal.page.html',
  styleUrls: ['./terminal-clock-modal.page.scss'],
})
export class TerminalClockModalPage implements OnInit {
  audio = new Audio('./assets/mp3/success.wav');

  audioF = new Audio('./assets/mp3/failled.mp3');

  modalTitle: string;
  modelId: number;
  initialDate = new Date();

  loading = false;

  page = 1;
  size = 100;
  id = 0;
  uid = 0;

  worktimes = [];

  isCreateWorkTimeDisabled = true;

  // this is the input value

  currentValue = '';

  counter = 0;

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @ViewChild('inputAutoFClockIn') myInput: any;

  // @ViewChild('inputAutoFClockIn', { static: false });

  // eslint-disable-next-line @typescript-eslint/member-ordering
  dataReturned: any;

  // eslint-disable-next-line @typescript-eslint/member-ordering

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Input() rESULT: ClockResult;

  constructor(
    private modalController: ModalController,
    private loadingCtrl: LoadingController,
    private toastController: ToastController,
    private router: Router,
    private cookies: Cookies,
    private terminalServe: TerminalServe,
    public navCtrl: NavController,
    private translateConfigService: TranslateConfigService,
    private translateService: TranslateService
  ) {
    this.translateConfigService.setLanguage(
      localStorage.getItem('translateLang')
    );}

  set inputAutoFClockIn(element: ElementRef<HTMLInputElement>) {
    if (element) {
      element.nativeElement.focus();
    }
  }

  ngOnInit() {
    this.counter = 15;
    this.CallTerminalTimerForDisplayed();
    this.focusButton();
    this.allwaysFocus();
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  CallTerminalTimerForDisplayed() {
    if (this.counter > -2 && this.counter <= 15) {
      const intervalId = setInterval(() => {
        this.counter = this.counter - 1;

        if (this.counter === 0) {
          clearInterval(intervalId);

          this.router.navigateByUrl(`/wtm/terminal`);
        }
      }, 1000);
    }
  }

  ionViewWillEnter() {
    this.counter = 10;
    this.CallTerminalTimerForDisplayed();
    this.focusButton();
    this.allwaysFocus();
  }

  async openSuccessTerminalClockInModal() {
    const msg = await firstValueFrom(this.translateService.get('TERMINALCLOCKEDINMODAL'));
    const modal = await this.modalController.create({
      component: SuccesTerminalModalPage,
      componentProps: {
        paramID: 124,
        paramTitle: msg,
      },
    });

    modal.onDidDismiss().then((dataReturned) => {
      if (dataReturned !== null) {
        this.dataReturned = dataReturned.data;
        //alert('Modal Sent Data :'+ dataReturned);
      }
    });

    return await modal.present();
  }

  onOpenClockTerminal() {
    this.counter = 0;
    this.focusButton();
    // this.router.navigateByUrl(`wtm/terminal-clock-modal`);
    this.navCtrl.navigateForward(`wtm/terminal-clock-modal`).then(() => {
      // window.location.reload();
    });
  }

  onOpenClockOutTerminal() {
    this.counter = 0;
    this.focusButton();
    this.navCtrl.navigateForward(`wtm/terminal-clock-out`).then(() => {
      // window.location.reload();
    });
  }

  onOpenInfoTerminal() {
    this.counter = 0;
    this.focusButton();
    this.navCtrl.navigateForward(`wtm/terminalinfo`).then(() => {
      // window.location.reload();
    });
  }
  closeModal() {
    this.counter = 0;
    this.router.navigateByUrl(`/wtm/terminal`);
  }

  focusButton(): void {
    const elm = document.getElementById('myField');
    if (elm !== undefined && elm !== null) {
      elm.focus();
    }
  }

  allwaysFocus() {
    const intervalId = setInterval(() => {
      this.focusButton();
    }, 1000);
  }

  async clockIn(cardid: string) {
    const val = new Int64Updater();

    val.Values = [];

    const cin = new KeyValue();
    cin.Key = 'clock_in';
    cin.Value = new Date().toString();
    val.Values.push(cin);

    this.loading = true;
    const load = await firstValueFrom(this.translateService.get('GENERAL.Processing'));
    this.loadingCtrl
      .create({ keyboardClose: true, message: load + '...' })
      .then(async (loadingEl) => {
        loadingEl.present();
        const res = await this.terminalServe
          .putTimeTracking(cardid, val)
          .toPromise()
          .catch((err) => this.handleError(err));
        if (res !== null && res === true) {
          // success
          const msg = await firstValueFrom(this.translateService.get('CLOCKEDIN'));
          await this.presentToast(msg);
        }
        loadingEl.dismiss();
        this.loading = false;

        //this.getWorkTimes();
      });
  }

  async clockOut(cardid: string) {
    const val = new Int64Updater();

    val.Values = [];

    const cout = new KeyValue();
    cout.Key = 'clock_out';
    cout.Value = new Date().toString();
    val.Values.push(cout);

    this.loading = true;

    const load = await firstValueFrom(this.translateService.get('GENERAL.Processing'));
    this.loadingCtrl
      .create({ keyboardClose: true, message: load + '...' })
      .then(async (loadingEl) => {
        loadingEl.present();
        const res = await this.terminalServe
          .putTimeTracking(cardid, val)
          .toPromise()
          .catch((err) => this.handleError(err));
        if (res !== null && res === true) {
          // success
          const msg = await firstValueFrom(this.translateService.get('CLOCKEDOUT'));
          await this.presentToast(msg);
        }

        loadingEl.dismiss();

        this.loading = false;

        //this.getWorkTimes();
      });
  }

  async addWorkTime(card: string) {
    const constants = new Constants();

    const user = this.cookies.getCookie(constants.userDataName);

    const wtime = new WorkTime();

    wtime.UserId = user.userId;

    // console.log('This is the card id when calling  the api: ' + card);

    wtime.CardId = card;
    wtime.Id = '';

    this.loading = true;

    // console.log('This is the object: ' + wtime.UserId);

    const load = await firstValueFrom(this.translateService.get('GENERAL.Processing'));
    this.loadingCtrl
      .create({ keyboardClose: true, message: load + '...' })
      .then(async (loadingEl) => {
        loadingEl.present();
        const resp = await this.terminalServe
          .postTerminalClockIn(wtime)
          .toPromise()
          .catch((err) => {
            this.handleError(err);
          });
        if (resp !== undefined && resp !== null) {

          const res = JSON.parse(resp);
          // success

          loadingEl.dismiss();

          this.loading = false;

          switch (res.userCard.card.isEnabled) {
            case 'Y':
              this.CallTerminalTimer();
              const result = new ClockResult();

              result.name = res.userCard.user.firstName;

              result.lastname = res.userCard.user.lastName;

              result.phone = res.userCard.user.phone;

              this.rESULT = result;
              // manage the clockin user
              const msg = await firstValueFrom(this.translateService.get('Welcome'));
              result.msg = msg;
              localStorage.setItem(
                card + 'username',
                result.name + ' ' + result.lastname
              );
              this.onOpenSuccessClockInTerminal(result);
              break;

            case 'N':
              const resultN = new ClockResult();

              resultN.name = '';

              resultN.lastname = '';

              localStorage.setItem(card + 'clockin', 'false');
              const emsg = await firstValueFrom(this.translateService.get('CARDDISABLED'));
              resultN.msg = emsg;
              this.CallTerminalTimerWhenFailled();
              this.onOpenFailledClockInTerminal(resultN);
              break;

            case 'X':
              const resultx = new ClockResult();

              resultx.name = '';

              resultx.lastname = '';
              localStorage.setItem(card + 'clockin', 'false');
              const emsg2 = await firstValueFrom(this.translateService.get('CARDNOTFOUND'));
              resultx.msg = emsg2;
              this.CallTerminalTimerWhenFailled();
              this.onOpenFailledClockInTerminal(resultx);
              break;
          }
        } else {
          loadingEl.dismiss();
          const result = new ClockResult();

          result.name = '';

          result.lastname = '';
          localStorage.setItem(card + 'clockin', 'false');
          const msg = await firstValueFrom(this.translateService.get('GENERAL.OPERATIONFAILED'));
          result.msg = msg;
          this.CallTerminalTimerWhenFailled();
          this.onOpenFailledClockInTerminal(result);
        }

        //  this.getWorkTimes();
      });
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  async CallTerminalTimerWhenFailled() {
    this.PlayFBip();

    await this.delay(4000);

    this.StopFBip();

    //this.router.navigateByUrl(`/wtm/terminal`);
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  async CallTerminalTimer() {
    this.PlayBip();

    await this.delay(4000);

    this.StopBip();

    //this.router.navigateByUrl(`/wtm/terminal`);
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  PlayFBip() {
    this.audioF.play();
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  StopFBip() {
    this.audioF.pause();
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  PlayBip() {
    this.audio.play();
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  StopBip() {
    this.audio.pause();
  }

  onOpenSuccessClockInTerminal(res: ClockResult) {
    this.navCtrl.navigateForward(`wtm/succes-terminal-modal`, {
      state: { res },
    });
    // this.router.navigateByUrl(`wtm/succes-terminal-modal`);
  }

  onOpenFailledClockInTerminal(res: ClockResult) {
    this.navCtrl.navigateForward(`wtm/failled-terminal-clock-in`, {
      state: { res },
    });
  }

  async onSubmit(form: NgForm) {
    this.counter = -1;

    let card = await form.value.clockinCardClockIn;
    const foodName = (document.getElementById('myField') as HTMLInputElement)
      .value;

    card = foodName;

    if (navigator.onLine) {
      if (localStorage.getItem(card + 'clockin') === null) {
        localStorage.setItem(card + 'clockin', 'true');
        localStorage.setItem(card + 'clockout', 'false');
        localStorage.setItem(card + 'time', '' + new Date().getTime());
        this.addWorkTime(card);
      } else {
        const clockin = localStorage.getItem(card + 'clockin');
        if (clockin === 'true') {
          const result = new ClockResult();
          result.name = 'Error';
          result.lastname = 'Message';
          const msg = await firstValueFrom(this.translateService.get('CLOCKINEXISTS'));
          result.msg = msg;
          this.CallTerminalTimerWhenFailled();
          this.onOpenFailledClockInTerminal(result);
        } else {
          localStorage.setItem(card + 'clockin', 'true');
          localStorage.setItem(card + 'clockout', 'false');
          localStorage.setItem(card + 'time', '' + new Date().getTime());
          this.addWorkTime(card);
        }
      }
      (document.getElementById('myField') as HTMLInputElement).value = '';
      form.reset();
    } else {
      this.CallTerminalTimerWhenFailled();
      const msg = await firstValueFrom(this.translateService.get('DEVICEOFFLINE'));
      localStorage.setItem(
        'error',
        msg
      );
      this.navCtrl.navigateForward(`wtm/bugmessage`).then(() => {
        // window.location.reload();
      });
    }
  }

  async presentToast(msg: string) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      color: 'success',
      position: 'top',
      cssClass: 'ion-text-center',
    });
    toast.present();
  }

  handleError(err: any) {
    console.log(err);
    this.loadingCtrl?.dismiss();
    this.loading = false;
  }
}
