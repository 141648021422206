import { Urls } from '@wtm/wtmlibrary';
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';

export class AppUrls {
  public apiUrl = environment.apiUrl;

  constructor() {
    this.apiUrl = Urls.apiUrl;
  }
}
