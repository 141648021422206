import { UrlGuardService } from './services/guards/url-guard/url-guard.service';
import { AuthGuardService } from './services/guards/auth-guard/auth-guard.service';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'wtm',
    loadChildren: () =>
      import('./loging/loging.module').then((m) => m.LogingPageModule),
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'wtm/times-loggings',
    loadChildren: () =>
      import('./times-loggings/times-loggings.module').then(
        (m) => m.TimesLoggingsPageModule
      ),
  },
  {
    path: 'wtm/times-tracking',
    loadChildren: () =>
      import('./time-tracking/time-tracking.module').then(
        (m) => m.TimeTrackingPageModule
      ),
  },
  {
    path: 'wtm/login',
    loadChildren: () =>
      import('./loging/loging.module').then((m) => m.LogingPageModule),
      canActivate: [ AuthGuardService ]
  },

  {
    path: '',
    redirectTo: 'wtm',
    pathMatch: 'full',
  },

  {
    path: 'wtm/terminal',
    loadChildren: () =>
      import('./terminal/terminal.module').then((m) => m.TerminalPageModule),
  },
  {
    path: 'terminal-modal',
    loadChildren: () =>
      import('./terminal-modal/terminal-modal.module').then(
        (m) => m.TerminalModalPageModule
      ),
  },
  {
    path: 'wtm/terminal-clock-modal',
    loadChildren: () =>
      import('./terminal-clock-modal/terminal-clock-modal.module').then(
        (m) => m.TerminalClockModalPageModule
      ),
  },
  {
    path: 'wtm/succes-terminal-modal',
    loadChildren: () =>
      import('./succes-terminal-modal/succes-terminal-modal.module').then(
        (m) => m.SuccesTerminalModalPageModule
      ),
  },
  {
    path: 'wtm/terminal-clock-out',
    loadChildren: () =>
      import('./terminal-clock-out/terminal-clock-out.module').then(
        (m) => m.TerminalClockOutPageModule
      ),
  },
  {
    path: 'wtm/failled-terminal-clock-out',
    // eslint-disable-next-line max-len
    loadChildren: () =>
      import(
        './failled-terminal-clock-out/failled-terminal-clock-out.module'
      ).then((m) => m.FailledTerminalClockOutPageModule),
  },
  {
    path: 'wtm/failled-terminal-clock-in',
    // eslint-disable-next-line max-len
    loadChildren: () =>
      import(
        './failled-terminal-clock-in/failled-terminal-clock-in.module'
      ).then((m) => m.FailledTerminalClockInPageModule),
  },
  {
    path: 'wtm/success-terminal-clock-out',
    // eslint-disable-next-line max-len
    loadChildren: () =>
      import(
        './success-terminal-clock-out/success-terminal-clock-out.module'
      ).then((m) => m.SuccessTerminalClockOutPageModule),
  },
  {
    path: 'wtm/list-checks-points',
    loadChildren: () =>
      import(
        './checksPoints/list-checks-points/list-checks-points.module'
      ).then((m) => m.ListChecksPointsPageModule),
  },
  {
    path: 'wtm/list-checks-points/check-point-terminal',
    loadChildren: () =>
      import(
        './checksPoints/check-point-terminal/check-point-terminal.module'
      ).then((m) => m.CheckPointTerminalPageModule),
  },
  {
    path: 'wtm/check-point-terminal-success',
    // eslint-disable-next-line max-len
    loadChildren: () =>
      import(
        './checksPoints/check-point-terminal-success/check-point-terminal-success.module'
      ).then((m) => m.CheckPointTerminalSuccessPageModule),
  },
  {
    path: 'wtm/check-point-terminal-failled',
    // eslint-disable-next-line max-len
    loadChildren: () =>
      import(
        './checksPoints/check-point-terminal-failled/check-point-terminal-failled.module'
      ).then((m) => m.CheckPointTerminalFailledPageModule),
  },
  {
    path: 'wtm/list-attendances',
    loadChildren: () =>
      import('./attendances/list-attendances/list-attendances.module').then(
        (m) => m.ListAttendancesPageModule
      ),
  },
  {
    path: 'wtm/list-attendances/terminal-attendance',
    loadChildren: () =>
      import(
        './attendances/terminal-attendance/terminal-attendance.module'
      ).then((m) => m.TerminalAttendancePageModule),
  },
  {
    path: 'wtm/terminal-attendance-success',
    // eslint-disable-next-line max-len
    loadChildren: () =>
      import(
        './attendances/terminal-attendance-success/terminal-attendance-success.module'
      ).then((m) => m.TerminalAttendanceSuccessPageModule),
  },
  {
    path: 'wtm/terminal-attendance-failled',
    // eslint-disable-next-line max-len
    loadChildren: () =>
      import(
        './attendances/terminal-attendance-failled/terminal-attendance-failled.module'
      ).then((m) => m.TerminalAttendanceFailledPageModule),
  },
  {
    path: 'wtm/terminalinfo',
    loadChildren: () => import('./terminalInfo/terminalinfo/terminalinfo.module').then( m => m.TerminalinfoPageModule)
  },
  {
    path: 'wtm/terminalinforesult',
    loadChildren: () => import('./terminalInfo/terminalinforesult/terminalinforesult.module').then( m => m.TerminalinforesultPageModule)
  },
  {
    path: 'wtm/bugmessage',
    loadChildren: () => import('./terminalInfo/bugmessage/bugmessage.module').then( m => m.BugmessagePageModule)
  },
  {
    path: 'wtm/landing-homepage',
    loadChildren: () => import('./landing-homepage/landing-homepage.module').then( m => m.LandingHomepagePageModule)
  },
  {
    path: 'wtm/session-paticipants',
    loadChildren: () => import('./session-paticipants/session-paticipants.module').then( m => m.SessionPaticipantsPageModule)
  },
  {
    path: 'wtm/setting',
    loadChildren: () => import('./setting/setting.module').then( m => m.SettingPageModule)
  },
  {
    path: 'wtm/card-list',
    loadChildren: () => import('./cards-folder/card-list/card-list.module').then( m => m.CardListPageModule)
  },
  {
    path: 'card-form',
    loadChildren: () => import('./cards-folder/card-form/card-form.module').then( m => m.CardFormPageModule)
  },
  {
    path: 'person-list',
    loadChildren: () => import('./person-folder/person-list/person-list.module').then( m => m.PersonListPageModule)
  },
  {
    path: 'person-form',
    loadChildren: () => import('./person-folder/person-form/person-form.module').then( m => m.PersonFormPageModule)
  },
  {
    path: 'person-update-form',
    loadChildren: () => import('./person-folder/person-update-form/person-update-form.module').then( m => m.PersonUpdateFormPageModule)
  },
  {
    path: 'wtm/accounts',
    loadChildren: () => import('./account/account.module').then( m => m.AccountPageModule),
    // canActivate: [AccountGuardService]
  },
  {
    path: 'wtm/accounts/detail',
    loadChildren: () => import('./account-detail/account-detail.module').then( m => m.AccountDetailPageModule),
    // canActivate: [AccountGuardService]
  },
  {
    path: 'wtm/report-overview',
    loadChildren: () => import('./report-overview/report-overview.module').then( m => m.ReportOverviewPageModule)
  },
  {path: 'session-form',
    loadChildren: () => import('./attendances/session-form/session-form.module').then( m => m.SessionFormPageModule)
  },
  {
    path: 'wtm/list-attendances/manual/user',
    loadChildren: () => import('./attendances/manual-attendance/user/user/user.module').then( m => m.UserPageModule)
  },
  {
    path: 'wtm/manage-sessions',
    loadChildren: () => import('./manage-sessions/manage-sessions.module').then( m => m.ManageSessionsPageModule)
  },
  {
    path: 'manage-sessions-edit',
    loadChildren: () => import('./manage-sessions-edit/manage-sessions-edit.module').then( m => m.ManageSessionsEditPageModule)
  },
  {
    path: 'manage-session-detail',
    loadChildren: () => import('./manage-session-detail/manage-session-detail.module').then( m => m.ManageSessionDetailPageModule)
  },
  {
    path: 'wtm/url',
    loadChildren: () => import('./url/url.module').then( m => m.UrlPageModule),
    canActivate: [ UrlGuardService ]
  },
  {
    path: 'wtm/calendar-obsolete',
    loadChildren: () => import('./calendar/calendar.module').then( m => m.CalendarPageModule)
  },
  {
    path: 'session-detail',
    loadChildren: () => import('./attendances/session-detail/session-detail.module').then( m => m.SessionDetailPageModule)
  },
  {
    path: 'session-types',
    loadChildren: () => import('./session-types/session-types/session-types.module').then( m => m.SessionTypesPageModule)
  },
  {
    path: 'config',
    loadChildren: () => import('./config/config.module').then( m => m.ConfigPageModule)
  },
  {
    path: 'profiles',
    loadChildren: () => import('./profiles/profiles.module').then( m => m.ProfilesPageModule)
  },
  {
    path: 'compliances',
    loadChildren: () => import('./compliance/compliances/compliances.module').then( m => m.CompliancesPageModule)
  },
  {
    path: 'compliance-scan',
    loadChildren: () => import('./compliance/compliance-scan/compliance-scan.module').then( m => m.ComplianceScanPageModule)
  },
  {
    path: 'compliance-confirm',
    loadChildren: () => import('./compliance/compliance-confirm/compliance-confirm.module').then( m => m.ComplianceConfirmPageModule)
  },
  {
    path: 'compliance-info',
    loadChildren: () => import('./compliance/compliance-info/compliance-info.module').then( m => m.ComplianceInfoPageModule)
  },




];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
