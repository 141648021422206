import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ClockResult } from '../models/clock_service';
import { firstValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-succes-terminal-modal',
  templateUrl: './succes-terminal-modal.page.html',
  styleUrls: ['./succes-terminal-modal.page.scss'],
})
export class SuccesTerminalModalPage implements OnInit {
  // @ViewChild('audio') audio;
  modalTitle: string;
  modelId: number;
  initialDate = new Date();
  counter: number;
  item: ClockResult;

  //audio = new Audio('./assets/mp3/Sonnerie-Basic-Bell-mp3-pour-telephone-SonnerieTelephone.mp3');
  msg = 'Welcome';

  constructor(
    private route: ActivatedRoute,
    private modalController: ModalController,
    private router: Router,
    private translateService: TranslateService
  ) {
    this.route.queryParams.subscribe((_p) => {
      const navParams = this.router.getCurrentNavigation().extras.state;
      console.log(navParams);
      if (navParams) {
        this.item = navParams.res;
      }
    });
  }

  async ngOnInit() {
    this.msg = await firstValueFrom(this.translateService.get('Welcome'));
    this.counter = 3;
    this.CallTerminalTimer();
  }

  async closeModal() {
    const onClosedData = 'Wrapped Up!';
    await this.modalController.dismiss(onClosedData);
  }

  onOpenClockTerminal() {
    this.counter = 0;
    this.router.navigateByUrl(`wtm/terminal-clock-modal`);
  }

  onOpenClockOutTerminal() {
    this.counter = 0;
    this.router.navigateByUrl(`wtm/terminal-clock-out`);
  }

  onOpenInfoTerminal() {
    this.counter = 0;
    this.router.navigateByUrl(`wtm/terminalinfo`);
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  CallTerminalTimer() {
    const intervalId = setInterval(() => {
      this.counter = this.counter - 1;
      console.log(this.counter);

      if (this.counter === 0) {
        clearInterval(intervalId);
        //this.StopBip();
        this.router.navigateByUrl(`/wtm/terminal`);
      }
    }, 1000);
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  PlayBip() {
    //this.audio.play();
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  StopBip() {
    //this.audio.pause();
  }

  // // eslint-disable-next-line @typescript-eslint/naming-convention
  // LoadBip() {
  //   this.audio.preload(
  //     'tabSwitch',
  //     './assets/mp3/Sonnerie-Basic-Bell-mp3-pour-telephone-SonnerieTelephone.mp3'
  //   );
  //   this.audio.play('tabSwitch');
  // }
}
