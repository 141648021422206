import { CheckUploadService } from './services/check-upload/check-upload.service';
import { LocalData } from './helpers/local-data';
import { AlertController, MenuController, NavController, Platform } from '@ionic/angular';
/* eslint-disable @typescript-eslint/naming-convention */
import { TerminalModalPage } from './terminal-modal/terminal-modal.page';
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import {
  LoadingController,
  ModalController,
  ToastController,
} from '@ionic/angular';
import { Cookies } from './helpers/cookies';
import { Constants } from './helpers/constants';
import { AuthService } from './services/auth/auth.service';
import { Profile } from './models/profile';
import { AuthServiceRefresher } from './services/editProfileService';
import { Roles } from './models/Roles';
import { SessionService, Urls, UrlService } from '@wtm/wtmlibrary';
import { environment } from 'src/environments/environment';
import { TranslateConfigService } from './services/translate-config.service';
import { Network } from '@awesome-cordova-plugins/network/ngx';

import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { DataCache } from './helpers/data-cache';
import { AppUrls } from './helpers/app-urls';
import { Location } from '@angular/common';

import { FirebaseCrashlytics } from '@capacitor-firebase/crashlytics';

import { Plugins } from '@capacitor/core';
import { Generic } from './helpers/generic';
import { TranslateService } from '@ngx-translate/core';
const { SplashScreen } = Plugins;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  profile: Profile;

  userrole: Roles;

  loading = false;

  activeButton = 'a';

  // class

  terminal = '';

  checkpoint = '';

  session = '';

  timelogging = '';

  timetracking = '';

  setting = '';

  administration = '';

  person = '';

  account = '';

  overview = '';

  calendar = '';

  sessiontype = '';
  config = '';
  profiles = '';

  compliance = '';

  isShowTimeLoggingSection = false;
  isShowTimeTrackingSection = false;
  isShowTerminalSection = false;
  isShowAttendanceSection = false;
  isShowCheckpointSection = false;
  isShowAccountSection = false;
  isShowStatisticsSection = false;
  isShowCardSection = false;
  isShowManageSessions = true;
  isShowPersonSection = false;
  isShowCalendarSection = false;
  isShowSessionTypeSection = false;
  isShowConfigSection = false;
  isShowProfileSection = false;
  isShowComplianceSection = true;

  page = 1;
  size = 100;
  id = 0;
  uid = 0;

  worktimes = [];

  onCheckLoad = false;

  // this is the e2e commande for mobile device "e2e": "tsc --p e2e/pro.tsconfig.json && protractor e2e/protractor.config.js --verbose"

  // eslint-disable-next-line @typescript-eslint/ban-types
  // eslint-disable-next-line @typescript-eslint/naming-convention
  // eslint-disable-next-line @typescript-eslint/ban-types
  public userParams: string;

  initialDate = new Date();
  isCreateWorkTimeDisabled = true;
  dataReturned: any;

  testvalue = false;
  testvalue1 = false;
  testvalue2 = false;
  testvalue3 = false;
  testvalue4 = false;
  selectedLanguage: string;
  ImgLetters = '';

  isRemainder = 0;
  isAdmin = 0;

  isadmin = false;

  disconnectSubscription: any;
  connectSubscription: any;
  isconnected = false;

  urlService: UrlService;

  version = '';

  uploadinterval: any;

  min = 1 * 60 * 1000; // 1 min
  min15 = 15 * 60 * 1000; // 15 min

  isUploaded = 'N';
  showBanner = false;
  isMobile = false;

  isPopoverOpen = false;

  private authService: AuthService;
  private refersherSessionService: AuthServiceRefresher;

  private sessionService: SessionService;
  private genericService: Generic;
  private checkUploadService: CheckUploadService;
  private alertController: AlertController;

  constructor(
    private loadingCtrl: LoadingController,
    private toastController: ToastController,
    public modalController: ModalController,
    private cookies: Cookies,
    private translateConfigService: TranslateConfigService,
    private nav: NavController,
    private platform: Platform,
    private network: Network,
    private injector: Injector,
    private appVersion: AppVersion,
    private menu: MenuController,
    private location: Location,
    private translateService: TranslateService
  ) {
    try {
      if ('serviceWorker' in navigator) {
        caches.keys().then((cacheNames) => {
          cacheNames.forEach((cacheName) => {
            caches.delete(cacheName);
          });
        });
      }

      const path = this.location.path();
      if (path.includes('login') || path === '/wtm' || path === '') {
        this.clearAll();
        this.showBanner = false;
      }

      if (path.includes('homepage') || path.includes('login') || path === '/wtm' || path === '') {
        LocalData.clearData();
      }

      if (localStorage.getItem('translateLang') === null) {
        this.selectedLanguage = this.translateConfigService.getDefaultLanguage();

        localStorage.setItem(
          'translateLang',
          this.translateConfigService.getDefaultLanguage()
        );
      } else {
        this.translateConfigService.setLanguage(
          localStorage.getItem('translateLang')
        );
      }

      this.alertController = this.injector.get(AlertController);
    } catch (error) {
      console.log('APP LAUNCH ERROR - CONSTRUCTOR', error);
    }
  }

  async ngOnInit() {
    try {
      if (this.platform.is('mobile') && !this.platform.is('mobileweb')) {
        this.isMobile = true;

        try {
          this.genericService = this.injector.get(Generic);
        } catch (error) {
          console.log('INJECT GENERIC SERVICE ERROR', error);
        }

        this.platform.ready().then(() => {
          FirebaseCrashlytics.sendUnsentReports()
          .catch(err => this.genericService.logEvent('sendCrashReportError', { error: JSON.stringify(err) }));
        });

        this.genericService.logEvent('appStartEvent', { appStartTime: new Date().toString()  });

        this.checkUploadService = this.injector.get(CheckUploadService);

        let run = true;
        while (run) {
          try {
            this.urlService = this.injector.get(UrlService);
            const url = await this.urlService.getUrl();
            Urls.apiUrl = url;
            environment.apiUrl = url;

            this.sessionService = this.injector.get(SessionService);

            run = false;
          } catch (error) {
            await this.delay(500);
            console.log('error', error);
            run = true;
          }
        }
      } else {
        // set api url for library
        Urls.apiUrl = environment.apiUrl;
      }

      new AppUrls();

      this.authService = this.injector.get(AuthService);
      this.refersherSessionService = this.injector.get(AuthServiceRefresher);

      this.platform.ready().then(async () => {
        setTimeout(()=>{
          try {
            SplashScreen.hide({
              fadeOutDuration:  1000
            });
          } catch (error) {
            //
          }
        }, 3000);
      });

      if (this.platform.is('mobile') && !this.platform.is('mobileweb')) {
        this.version = await this.appVersion.getVersionNumber();
      }

      this.setMenuStatus();
      this.setUserParams();

      this.cacheData();

      if (this.platform.is('mobile') && !this.platform.is('mobileweb')) {
        this.checkUploadService.sessionsUploaded$.subscribe((uploaded) => {
          this.isUploaded = uploaded;
        });

        this.checkUploadService.isLogin$.subscribe((value) => {
          this.showBanner = !value;
        });

        this.runBackgroundProcess();
        this.getUserCardsOnAppLaunch();
      }
    } catch (error) {
      console.log('APP LAUNCH ERROR - INIT', error);
    }
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  setMenuStatus() {
    if (localStorage.getItem('active_timetracking') !== null) {
      this.timetracking = localStorage.getItem('active_timetracking');
    }

    if (localStorage.getItem('active_session') !== null) {
      this.session = localStorage.getItem('active_session');
    }

    if (localStorage.getItem('active_terminal') !== null) {
      this.terminal = localStorage.getItem('active_terminal');
    }

    if (localStorage.getItem('active_checkpoint') !== null) {
      this.checkpoint = localStorage.getItem('active_checkpoint');
    }

    if (localStorage.getItem('active_timelogging') !== null) {
      this.timelogging = localStorage.getItem('active_timelogging');
    }

    if (localStorage.getItem('active_setting') !== null) {
      this.setting = localStorage.getItem('active_setting');
    }

    // card menu
    if (localStorage.getItem('active_administration') !== null
        && localStorage.getItem('active_administration') !== '') {
      this.isAdmin = 1;
      this.administration = localStorage.getItem('active_administration');
    }

    if (localStorage.getItem('active_person') !== null) {
      this.person = localStorage.getItem('active_person');
    }

    if (localStorage.getItem('active_account') !== null) {
      this.account = localStorage.getItem('active_account');
    }

    if (localStorage.getItem('active_overview') !== null) {
      if (localStorage.getItem('active_overview') !== '') {
        this.isRemainder = 1;
      }
      this.overview = localStorage.getItem('active_overview');
    }

    if (localStorage.getItem('active_calendar') !== null) {
      if (localStorage.getItem('active_calendar') !== '') {
        this.isAdmin = 1;
      }
      this.calendar = localStorage.getItem('active_calendar');
    }

    if (localStorage.getItem('active_sessiontype') !== null) {
      if (localStorage.getItem('active_sessiontype') !== '') {
        this.isAdmin = 1;
      }
      this.sessiontype = localStorage.getItem('active_sessiontype');
    }

    if (localStorage.getItem('active_config') !== null) {
      if (localStorage.getItem('active_config') !== '') {
        this.isAdmin = 1;
      }
      this.config = localStorage.getItem('active_config');
    }

    if (localStorage.getItem('active_profile') !== null) {
      if (localStorage.getItem('active_profile') !== '') {
        this.isAdmin = 1;
      }
      this.profiles = localStorage.getItem('active_profile');
    }

    if (localStorage.getItem('active_compliance') !== null) {
      this.compliance = localStorage.getItem('active_compliance');
    }

  }

  onTerminalClick() {
    this.terminal = 'active';
    this.session = '';
    this.checkpoint = '';
    this.timetracking = '';
    this.timelogging = '';
    this.setting = '';
    this.administration = '';
    this.person = '';
    this.account = '';
    this.overview = '';
    this.calendar = '';
    this.sessiontype = '';
    this.config = '';
    this.profiles = '';

    localStorage.setItem('active_timetracking', '');
    localStorage.setItem('active_session', '');
    localStorage.setItem('active_terminal', 'active');
    localStorage.setItem('active_checkpoint', '');
    localStorage.setItem('active_timelogging', '');
    localStorage.setItem('active_setting', '');
    localStorage.setItem('active_administration', '');
    localStorage.setItem('active_person', '');
    localStorage.setItem('active_account', '');
    localStorage.setItem('active_overview', '');
    localStorage.setItem('active_calendar', '');
    localStorage.setItem('active_sessiontype', '');
    localStorage.setItem('active_config', '');
    localStorage.setItem('active_profile', '');

    this.compliance = '';
    localStorage.setItem('active_compliance', '');

    this.isRemainder = 0;
    this.isAdmin = 0;
  }

  onCompliance() {
    this.session = '';
    this.terminal = '';
    this.checkpoint = '';
    this.timetracking = '';
    this.timelogging = '';
    this.setting = '';
    this.administration = '';
    this.person = '';
    this.account = '';
    this.overview = '';
    this.calendar = '';
    this.sessiontype = '';
    this.config = '';
    this.profiles = '';

    localStorage.setItem('active_timetracking', '');
    localStorage.setItem('active_session', '');
    localStorage.setItem('active_terminal', '');
    localStorage.setItem('active_checkpoint', '');
    localStorage.setItem('active_timelogging', '');
    localStorage.setItem('active_setting', '');
    localStorage.setItem('active_administration', '');
    localStorage.setItem('active_person', '');
    localStorage.setItem('active_account', '');
    localStorage.setItem('active_overview', '');
    localStorage.setItem('active_calendar', '');
    localStorage.setItem('active_sessiontype', '');
    localStorage.setItem('active_config', '');
    localStorage.setItem('active_profile', '');

    this.compliance = 'active';
    localStorage.setItem('active_compliance', 'active');

    this.isRemainder = 0;
    this.isAdmin = 0;
  }

  onSession() {
    this.session = 'active';
    this.terminal = '';
    this.checkpoint = '';
    this.timetracking = '';
    this.timelogging = '';
    this.setting = '';
    this.administration = '';
    this.person = '';
    this.account = '';
    this.overview = '';
    this.calendar = '';
    this.sessiontype = '';
    this.config = '';
    this.profiles = '';

    localStorage.setItem('active_timetracking', '');
    localStorage.setItem('active_session', 'active');
    localStorage.setItem('active_terminal', '');
    localStorage.setItem('active_checkpoint', '');
    localStorage.setItem('active_timelogging', '');
    localStorage.setItem('active_setting', '');
    localStorage.setItem('active_administration', '');
    localStorage.setItem('active_person', '');
    localStorage.setItem('active_account', '');
    localStorage.setItem('active_overview', '');
    localStorage.setItem('active_calendar', '');
    localStorage.setItem('active_sessiontype', '');
    localStorage.setItem('active_config', '');
    localStorage.setItem('active_profile', '');

    this.compliance = '';
    localStorage.setItem('active_compliance', '');

    this.isRemainder = 0;
    this.isAdmin = 0;
  }

  onCheckpoint() {
    this.session = '';
    this.terminal = '';
    this.checkpoint = 'active';
    this.timetracking = '';
    this.timelogging = '';
    this.setting = '';
    this.administration = '';
    this.account = '';
    this.overview = '';
    this.calendar = '';
    this.sessiontype = '';
    this.config = '';
    this.profiles = '';

    localStorage.setItem('active_timetracking', '');
    localStorage.setItem('active_session', '');
    localStorage.setItem('active_terminal', '');
    localStorage.setItem('active_checkpoint', 'active');
    localStorage.setItem('active_timelogging', '');
    localStorage.setItem('active_setting', '');
    localStorage.setItem('active_administration', '');
    localStorage.setItem('active_person', '');
    localStorage.setItem('active_account', '');
    localStorage.setItem('active_overview', '');
    localStorage.setItem('active_calendar', '');
    localStorage.setItem('active_sessiontype', '');
    localStorage.setItem('active_config', '');
    localStorage.setItem('active_profile', '');

    this.compliance = '';
    localStorage.setItem('active_compliance', '');
    this.person = '';

    this.isRemainder = 0;
    this.isAdmin = 0;
  }

  onTimeLogging() {
    this.session = '';
    this.terminal = '';
    this.checkpoint = '';
    this.timetracking = '';
    this.timelogging = 'active';
    this.setting = '';
    this.administration = '';
    this.account = '';
    this.overview = '';
    this.calendar = '';
    this.sessiontype = '';
    this.config = '';
    this.profiles = '';

    localStorage.setItem('active_timetracking', '');
    localStorage.setItem('active_session', '');
    localStorage.setItem('active_terminal', '');
    localStorage.setItem('active_checkpoint', '');
    localStorage.setItem('active_timelogging', 'active');
    localStorage.setItem('active_setting', '');
    localStorage.setItem('active_administration', '');
    localStorage.setItem('active_person', '');
    localStorage.setItem('active_account', '');
    localStorage.setItem('active_overview', '');
    localStorage.setItem('active_calendar', '');
    localStorage.setItem('active_sessiontype', '');
    localStorage.setItem('active_config', '');
    localStorage.setItem('active_profile', '');

    this.compliance = '';
    localStorage.setItem('active_compliance', '');
    this.person = '';

    this.isRemainder = 0;
    this.isAdmin = 0;
  }

  onTimeTracking() {
    this.session = '';
    this.terminal = '';
    this.checkpoint = '';
    this.timetracking = 'active';
    this.timelogging = '';
    this.setting = '';
    this.administration = '';
    this.person = '';
    this.account = '';
    this.overview = '';
    this.calendar = '';
    this.sessiontype = '';
    this.config = '';
    this.profiles = '';

    localStorage.setItem('active_timetracking', 'active');
    localStorage.setItem('active_session', '');
    localStorage.setItem('active_terminal', '');
    localStorage.setItem('active_checkpoint', '');
    localStorage.setItem('active_timelogging', '');
    localStorage.setItem('active_setting', '');
    localStorage.setItem('active_administration', '');
    localStorage.setItem('active_person', '');
    localStorage.setItem('active_account', '');
    localStorage.setItem('active_overview', '');
    localStorage.setItem('active_calendar', '');
    localStorage.setItem('active_sessiontype', '');
    localStorage.setItem('active_config', '');
    localStorage.setItem('active_profile', '');

    this.compliance = '';
    localStorage.setItem('active_compliance', '');

    this.isRemainder = 0;
    this.isAdmin = 0;
  }

  onSetting() {
    this.session = '';
    this.terminal = '';
    this.checkpoint = '';
    this.timetracking = '';
    this.timelogging = '';
    this.setting = 'active';
    this.administration = '';
    this.person = '';
    this.account = '';
    this.overview = '';
    this.calendar = '';
    this.sessiontype = '';
    this.config = '';
    this.profiles = '';

    localStorage.setItem('active_timetracking', '');
    localStorage.setItem('active_session', '');
    localStorage.setItem('active_terminal', '');
    localStorage.setItem('active_checkpoint', '');
    localStorage.setItem('active_timelogging', '');
    localStorage.setItem('active_setting', 'active');
    localStorage.setItem('active_administration', '');
    localStorage.setItem('active_person', '');
    localStorage.setItem('active_account', '');
    localStorage.setItem('active_overview', '');
    localStorage.setItem('active_calendar', '');
    localStorage.setItem('active_sessiontype', '');
    localStorage.setItem('active_config', '');
    localStorage.setItem('active_profile', '');

    this.compliance = '';
    localStorage.setItem('active_compliance', '');

    this.isRemainder = 0;
    this.isAdmin = 0;
  }

  onAdministration() {
    this.session = '';
    this.terminal = '';
    this.checkpoint = '';
    this.timetracking = '';
    this.timelogging = '';
    this.setting = '';
    this.administration = 'active';
    this.person = '';
    this.account = '';
    this.overview = '';
    this.calendar = '';
    this.sessiontype = '';
    this.config = '';
    this.profiles = '';

    localStorage.setItem('active_timetracking', '');
    localStorage.setItem('active_session', '');
    localStorage.setItem('active_terminal', '');
    localStorage.setItem('active_checkpoint', '');
    localStorage.setItem('active_timelogging', '');
    localStorage.setItem('active_setting', '');
    localStorage.setItem('active_setting', '');
    localStorage.setItem('active_administration', 'active');
    localStorage.setItem('active_person', '');
    localStorage.setItem('active_account', '');
    localStorage.setItem('active_overview', '');
    localStorage.setItem('active_calendar', '');
    localStorage.setItem('active_sessiontype', '');
    localStorage.setItem('active_config', '');
    localStorage.setItem('active_profile', '');

    this.compliance = '';
    localStorage.setItem('active_compliance', '');

    this.isRemainder = 0;
  }

  onPerson() {
    this.session = '';
    this.terminal = '';
    this.checkpoint = '';
    this.timetracking = '';
    this.timelogging = '';
    this.setting = '';
    this.administration = '';
    this.person = 'active';
    this.account = '';
    this.overview = '';
    this.calendar = '';
    this.sessiontype = '';
    this.config = '';
    this.profiles = '';

    localStorage.setItem('active_timetracking', '');
    localStorage.setItem('active_session', '');
    localStorage.setItem('active_terminal', '');
    localStorage.setItem('active_checkpoint', '');
    localStorage.setItem('active_timelogging', '');
    localStorage.setItem('active_setting', '');
    localStorage.setItem('active_setting', '');
    localStorage.setItem('active_administration', '');
    localStorage.setItem('active_person', 'active');
    localStorage.setItem('active_account', '');
    localStorage.setItem('active_overview', '');
    localStorage.setItem('active_calendar', '');
    localStorage.setItem('active_sessiontype', '');
    localStorage.setItem('active_config', '');
    localStorage.setItem('active_profile', '');

    this.compliance = '';
    localStorage.setItem('active_compliance', '');

    this.isRemainder = 0;
    this.isAdmin = 0;
  }

  onAccount() {
    this.session = '';
    this.terminal = '';
    this.checkpoint = '';
    this.timetracking = '';
    this.timelogging = '';
    this.setting = '';
    this.administration = '';
    this.account = 'active';
    this.overview = '';
    this.person = '';
    this.calendar = '';
    this.sessiontype = '';
    this.config = '';
    this.profiles = '';

    localStorage.setItem('active_timetracking', '');
    localStorage.setItem('active_session', '');
    localStorage.setItem('active_terminal', '');
    localStorage.setItem('active_checkpoint', '');
    localStorage.setItem('active_timelogging', '');
    localStorage.setItem('active_setting', '');
    localStorage.setItem('active_setting', '');
    localStorage.setItem('active_administration', '');
    localStorage.setItem('active_account', 'active');
    localStorage.setItem('active_overview', '');
    localStorage.setItem('active_person', '');
    localStorage.setItem('active_calendar', '');
    localStorage.setItem('active_sessiontype', '');
    localStorage.setItem('active_config', '');
    localStorage.setItem('active_profile', '');

    this.compliance = '';
    localStorage.setItem('active_compliance', '');

    this.isRemainder = 0;
    this.isAdmin = 0;
  }

  onOverview() {
    this.session = '';
    this.terminal = '';
    this.checkpoint = '';
    this.timetracking = '';
    this.timelogging = '';
    this.setting = '';
    this.administration = '';
    this.account = '';
    this.overview = 'active';
    this.person = '';
    this.calendar = '';
    this.sessiontype = '';
    this.config = '';
    this.profiles = '';

    localStorage.setItem('active_timetracking', '');
    localStorage.setItem('active_session', '');
    localStorage.setItem('active_terminal', '');
    localStorage.setItem('active_checkpoint', '');
    localStorage.setItem('active_timelogging', '');
    localStorage.setItem('active_setting', '');
    localStorage.setItem('active_setting', '');
    localStorage.setItem('active_administration', '');
    localStorage.setItem('active_account', '');
    localStorage.setItem('active_overview', 'active');
    localStorage.setItem('active_person', '');
    localStorage.setItem('active_calendar', '');
    localStorage.setItem('active_sessiontype', '');
    localStorage.setItem('active_config', '');
    localStorage.setItem('active_profile', '');

    this.compliance = '';
    localStorage.setItem('active_compliance', '');

    this.isAdmin = 0;
  }

  onCalendar() {
    this.session = '';
    this.terminal = '';
    this.checkpoint = '';
    this.timetracking = '';
    this.timelogging = '';
    this.setting = '';
    this.administration = '';
    this.person = '';
    this.account = '';
    this.overview = '';
    this.calendar = 'active';
    this.sessiontype = '';
    this.config = '';
    this.profiles = '';

    localStorage.setItem('active_timetracking', '');
    localStorage.setItem('active_session', '');
    localStorage.setItem('active_terminal', '');
    localStorage.setItem('active_checkpoint', '');
    localStorage.setItem('active_timelogging', '');
    localStorage.setItem('active_setting', '');
    localStorage.setItem('active_setting', '');
    localStorage.setItem('active_administration', '');
    localStorage.setItem('active_person', '');
    localStorage.setItem('active_account', '');
    localStorage.setItem('active_overview', '');
    localStorage.setItem('active_calendar', 'active');
    localStorage.setItem('active_sessiontype', '');
    localStorage.setItem('active_config', '');
    localStorage.setItem('active_profile', '');

    this.compliance = '';
    localStorage.setItem('active_compliance', '');

    this.isRemainder = 0;
  }

  onSessionType() {
    this.session = '';
    this.terminal = '';
    this.checkpoint = '';
    this.timetracking = '';
    this.timelogging = '';
    this.setting = '';
    this.administration = '';
    this.person = '';
    this.account = '';
    this.overview = '';
    this.calendar = '';
    this.sessiontype = 'active';
    this.config = '';
    this.profiles = '';

    localStorage.setItem('active_timetracking', '');
    localStorage.setItem('active_session', '');
    localStorage.setItem('active_terminal', '');
    localStorage.setItem('active_checkpoint', '');
    localStorage.setItem('active_timelogging', '');
    localStorage.setItem('active_setting', '');
    localStorage.setItem('active_setting', '');
    localStorage.setItem('active_administration', '');
    localStorage.setItem('active_person', '');
    localStorage.setItem('active_account', '');
    localStorage.setItem('active_overview', '');
    localStorage.setItem('active_calendar', '');
    localStorage.setItem('active_sessiontype', 'active');
    localStorage.setItem('active_config', '');
    localStorage.setItem('active_profile', '');

    this.compliance = '';
    localStorage.setItem('active_compliance', '');

    this.isRemainder = 0;
  }

  onConfig() {
    this.session = '';
    this.terminal = '';
    this.checkpoint = '';
    this.timetracking = '';
    this.timelogging = '';
    this.setting = '';
    this.administration = '';
    this.person = '';
    this.account = '';
    this.overview = '';
    this.calendar = '';
    this.sessiontype = '';
    this.config = 'active';
    this.profiles = '';

    localStorage.setItem('active_timetracking', '');
    localStorage.setItem('active_session', '');
    localStorage.setItem('active_terminal', '');
    localStorage.setItem('active_checkpoint', '');
    localStorage.setItem('active_timelogging', '');
    localStorage.setItem('active_setting', '');
    localStorage.setItem('active_setting', '');
    localStorage.setItem('active_administration', '');
    localStorage.setItem('active_person', '');
    localStorage.setItem('active_account', '');
    localStorage.setItem('active_overview', '');
    localStorage.setItem('active_calendar', '');
    localStorage.setItem('active_sessiontype', '');
    localStorage.setItem('active_config', 'active');
    localStorage.setItem('active_profile', '');

    this.compliance = '';
    localStorage.setItem('active_compliance', '');

    this.isRemainder = 0;
  }

  onProfile() {
    this.session = '';
    this.terminal = '';
    this.checkpoint = '';
    this.timetracking = '';
    this.timelogging = '';
    this.setting = '';
    this.administration = '';
    this.person = '';
    this.account = '';
    this.overview = '';
    this.calendar = '';
    this.sessiontype = '';
    this.config = '';
    this.profiles = 'active';

    localStorage.setItem('active_timetracking', '');
    localStorage.setItem('active_session', '');
    localStorage.setItem('active_terminal', '');
    localStorage.setItem('active_checkpoint', '');
    localStorage.setItem('active_timelogging', '');
    localStorage.setItem('active_setting', '');
    localStorage.setItem('active_setting', '');
    localStorage.setItem('active_administration', '');
    localStorage.setItem('active_person', '');
    localStorage.setItem('active_account', '');
    localStorage.setItem('active_overview', '');
    localStorage.setItem('active_calendar', '');
    localStorage.setItem('active_sessiontype', '');
    localStorage.setItem('active_config', '');
    localStorage.setItem('active_profile', 'active');

    this.compliance = '';
    localStorage.setItem('active_compliance', '');

    this.isRemainder = 0;
  }

  languageChanged() {
    this.translateConfigService.setLanguage(this.selectedLanguage);
  }

  ionViewWillEnter() {
    this.setUserParams();
  }

  async setUserParams() {
    // eslint-disable-next-line prefer-const
    let camera = false;

    this.authService.watchUserRoles().subscribe((userrole) => {
      // console.log(userrole)
      if (userrole !== null) {
        this.isShowTimeLoggingSection = false;
        this.isShowTimeTrackingSection = false;
        this.isShowTerminalSection = false;
        this.isShowAttendanceSection = false;
        this.isShowCheckpointSection = false;
        this.isShowAccountSection = false;
        this.isShowStatisticsSection = false;
        this.isShowCardSection = false;
        this.isShowPersonSection = false;
        this.isShowCalendarSection = false;
        this.isShowSessionTypeSection = false;
        this.isShowConfigSection = false;
        this.isShowProfileSection = false;
        this.isShowComplianceSection = false;

        if (userrole.timeTrackingSection === true) {
          this.testvalue4 = userrole.timeTrackingSection;
          this.isShowTimeTrackingSection = true;
        }
        if (userrole.timeLoggingSection === true) {
          this.isShowTimeLoggingSection = true;
          this.testvalue3 = userrole.timeLoggingSection;
        }
        if (userrole.sessionSection === true) {
          this.testvalue2 = true;
          this.isShowAttendanceSection = true;
        }

        if (userrole.terminalSection === true) {
          this.testvalue1 = true;
          this.isShowTerminalSection = true;
        }

        if (userrole.checkPointSection === true) {
          this.testvalue = userrole.checkPointSection;
          this.isShowCheckpointSection = true;
        }

        if (userrole.accountSection === true) {
          this.isShowAccountSection = true;
        }

        if (userrole.statisticsSection === true) {
          this.isShowStatisticsSection = true;
        }

        if (userrole.cardSection === true) {
          this.isShowCardSection = true;
        }

        if (userrole.personSection === true) {
          this.isShowPersonSection = true;
        }

        if (userrole.calendarSection === true) {
          this.isShowCalendarSection = true;
        }

        if (userrole.sessionTypeSection === true) {
          this.isShowSessionTypeSection = true;
        }

        if (userrole.configSection === true) {
          this.isShowConfigSection = true;
        }

        if (userrole.profileSection === true) {
          this.isShowProfileSection = true;
        }

        if (userrole.complianceSection === true) {
          this.isShowComplianceSection = true;
        }

        this.userParams = userrole.firstName + ' ' + userrole.lastName;
        this.ImgLetters =
          userrole.firstName.split('', 2)[0] +
          userrole.lastName.split('', 2)[0];

        if (this.platform.is('mobile') && !this.platform.is('mobileweb')) {
          this.genericService.setUser();
        }
      } else {
        camera = true;
      }
    });

    if (camera) {
      await this.refersherSessionService.refresherFunc();
      this.refersherSessionService
        .watchUserRoles()
        .subscribe((userroleView) => {
          // console.log(userroleView)
          if (userroleView !== null) {
            this.isShowTimeLoggingSection = false;
            this.isShowTimeTrackingSection = false;
            this.isShowTerminalSection = false;
            this.isShowAttendanceSection = false;
            this.isShowCheckpointSection = false;
            this.isShowAccountSection = false;
            this.isShowStatisticsSection = false;
            this.isShowCardSection = false;
            this.isShowPersonSection = false;
            this.isShowCalendarSection = false;
            this.isShowSessionTypeSection = false;
            this.isShowConfigSection = false;
            this.isShowProfileSection = false;
            this.isShowComplianceSection = false;

            if (userroleView.timeTrackingSection === true) {
              this.isShowTimeTrackingSection = true;
            }
            if (userroleView.timeLoggingSection === true) {
              this.isShowTimeLoggingSection = true;
            }
            if (userroleView.sessionSection === true) {
              this.isShowAttendanceSection = true;
            }

            if (userroleView.terminalSection === true) {
              this.isShowTerminalSection = true;
            }

            if (userroleView.checkPointSection === true) {
              this.isShowCheckpointSection = true;
            }

            if (userroleView.accountSection === true) {
              this.isShowAccountSection = true;
            }

            if (userroleView.statisticsSection === true) {
              this.isShowStatisticsSection = true;
            }

            if (userroleView.cardSection === true) {
              this.isShowCardSection = true;
            }

            if (userroleView.personSection === true) {
              this.isShowPersonSection = true;
            }

            if (userroleView.calendarSection === true) {
              this.isShowCalendarSection = true;
            }

            if (userroleView.sessionTypeSection === true) {
              this.isShowSessionTypeSection = true;
            }

            if (userroleView.configSection === true) {
              this.isShowConfigSection = true;
            }

            if (userroleView.profileSection === true) {
              this.isShowProfileSection = true;
            }

            if (userroleView.complianceSection === true) {
              this.isShowComplianceSection = true;
            }

            this.userParams =
              userroleView.firstName + ' ' + userroleView.lastName;

            this.ImgLetters =
              userroleView?.firstName?.split('', 2)[0] +
              userroleView?.lastName?.split('', 2)[0];

            if (this.platform.is('mobile') && !this.platform.is('mobileweb')) {
              this.genericService.setUser();
            }
          }
        });
    }
  }

  doRefresh(event) {
    this.setUserParams();
    console.log('Begin async operation');

    setTimeout(() => {
      console.log('Async operation has ended');
      event.target.complete();
    }, 2000);
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data

    this.modalController.dismiss({
      dismissed: true,
    });
  }

  // using the injected ModalController this page
  // can "dismiss" itself and optionally pass back data

  async openModal() {
    const modal = await this.modalController.create({
      component: TerminalModalPage,
      componentProps: {
        paramID: 123,
        paramTitle: 'Terminal home Modal',
      },
    });

    modal.onDidDismiss().then((dataReturned) => {
      if (dataReturned !== null) {
        this.dataReturned = dataReturned.data;
      }
    });

    return await modal.present();
  }

  async presentToast(msg: string) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      color: 'success',
      position: 'top',
      cssClass: 'ion-text-center',
    });
    toast.present();
  }

  cacheData() {
    const orgid = +localStorage.getItem('organisationId');
    if (orgid === undefined || orgid === null || orgid === 0) {
      return;
    }
    new DataCache(this.injector);
    DataCache.loadData();
    // 600000 milliseconds = 10 mins
    // tslint:disable-next-line: only-arrow-functions
    setInterval(() => {
      DataCache.loadData();
    }, 600000);
  }

  handleError(err) {
    console.log(err);
    this.loadingCtrl?.dismiss();
    this.loading = false;
  }

  clearAll() {
    const constants = new Constants();
    this.cookies.deleteCookie(constants.cookieDataName);
    this.cookies.deleteCookie(constants.cookieRolesName);
    localStorage.setItem('token', '');
    localStorage.removeItem('organisationId');
  }

  async onLogout() {
    if (this.platform.is('mobile') && !this.platform.is('mobileweb')) {
      // check if there still sessions not yet uploaded
      const sess = await this.sessionService.getDataToUpload();
      const attempts = await this.sessionService.getFailedAttempts(0, 0);
      if (sess.length > 0 || attempts.length > 0) {
        // display warning
        this.isPopoverOpen = true;
      } else {
        this.logout();
      }
    } else {
      this.logout();
    }
  }

  cancelLogout() {
    // do nothing
    this.isPopoverOpen = false;
  }

  logout() {
    this.isPopoverOpen = false;
    this.clearAll();

    this.isAdmin = 0;
    LocalData.clearData();
    this.setMenuStatus();
    this.menu.close().then(async () => {
      console.log(true);
      await this.nav.navigateRoot('wtm/login');
    });
  }

  checkConnection() {
    if (this.network.type === null || this.network.type === this.network.Connection.NONE) {
      // not connected
      this.isconnected = false;
    } else {
      this.isconnected = true;
    }

    // watch network for a disconnection
    this.disconnectSubscription = this.network.onDisconnect().subscribe(() => {
      console.log('network was disconnected', navigator.onLine);
      this.isconnected = false;
    });

    // stop disconnect watch
    // disconnectSubscription.unsubscribe()

    // watch network for a connection
    this.connectSubscription = this.network.onConnect().subscribe(() => {
      console.log('network connected', navigator.onLine);
      this.isconnected = true;
      // We just got a connection but we need to wait briefly
      // before we determine the connection type. Might need to wait.
      // prior to doing any api requests as well.
      setTimeout(() => {
        console.log(this.network.type + ' connection');
      }, 2000);
    });

    // stop connect watch
    // connectSubscription.unsubscribe()
  }

  runBackgroundProcess() {
    if (this.platform.is('mobile') && !this.platform.is('mobileweb')) {
      this.uploadinterval = setInterval(async () => {
        this.genericService.logEvent('uploadDataBackgroundProcessTriggered', { curentTime: new Date().toString() });
        // upload data
        console.log('UPLOADING DATA...', new Date());
        try {
          const sess = await this.sessionService.getDataToUpload();
          const attempts = await this.sessionService.getFailedAttempts(0, 0);
          if (sess.length === 0 && attempts.length === 0) {
            this.isUploaded = 'Y';
          } else {
            this.isUploaded = 'N';
          }

          localStorage.setItem('uploading', '1');
          // upload session data
          const ures = await this.sessionService.uploadSessionData(true).catch(err => {
            console.log('UPLOAD ERROR', err);
            localStorage.setItem('uploading', '0');
            this.genericService.logEvent('uploadDataBackgroundProcessError', { error: JSON.stringify(err) });
          });
          localStorage.setItem('uploading', '0');
        } catch (error) {
          localStorage.setItem('uploading', '0');
          console.log('UPLOAD ERROR', error);
          this.genericService.logEvent('uploadDataBackgroundProcessError', { error: JSON.stringify(error) });
        }
      }, this.min);
    }
  }

  async getUserCardsOnAppLaunch() {
    this.genericService.downloadData();
  }

  ngOnDestroy() {
    console.log('APP DESTROYED');
    if (this.uploadinterval !== undefined && this.uploadinterval !== null) {
      console.log('CLEARING UPLOAD INTERVAL...');
      clearInterval(this.uploadinterval);
    }
  }

}
